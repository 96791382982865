import RecentContacts from "./RecentContacts";
import DelayedContacts from "./DelayedContacts";
import ResolvedContacts from "./ResolvedContacts";
import { IConversationParent } from "../../models/contactData";

interface IContactWrapper {
  id: string,
  contacts: IConversationParent,
  selectedContactType: string,
}

function ContactsWrapper({ id, contacts, selectedContactType }: IContactWrapper) {
  return (<>
    {
      selectedContactType === "Last 24 Hours" &&
      (<RecentContacts id={id} contacts={contacts["Last 24 Hours"]} />)
    }
    <>
      {
        selectedContactType === "Resolved" && (
          <ResolvedContacts id={id} contacts={contacts["Resolved"]} />
        )
      }
    </>
    <>
      {
        selectedContactType === "Delayed" && (<DelayedContacts id={id} contacts={contacts["Delayed"]} />)
      }
    </>
  </>);
}

export default ContactsWrapper;

import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_CONTACT_SAGA,
  FETCH_CONTACTS_SAGA,
  MODIFY_CONTACT_SAGA,
  REMOVE_CONTACT_SAGA,
  UPDATE_CONTACT_READ_COUNT,
  UPDATE_CONTACT_TICKET_STATUS,
} from "./contacts.actiontypes";
import {
  contactsFetchSuccess,
  contactsFetchFailure,
  contactModifiedSuccess,
  contactAddedSuccess,
  contactRemovedSuccess,
  contactStatusUpdateSuccess,
} from "./contacts.slice";
import {
  getContactsByAccount,
  getMessageByMessageId,
  updateMessageCountOnRead,
  updateTicketStatus,
} from "../../application/collectionAccessor";
import {
  IContactsErrorModel,
  IConversationListItem, IConversationParent
} from "../../models/contactData";
import {
  Either,
  isLeft,
  unwrapEither,
} from "../../framework/monads/either.monad";
import { IErrorModel } from "../../models/errorModel";
import { IMessageInformation } from "../../models/message";

function* fetchContactsSaga(payload) {
  try {
    const response: Either<IContactsErrorModel, IConversationParent> =
      yield call(getContactsByAccount, payload.phoneNumber);
    if (isLeft(response)) {
      const error = unwrapEither<IContactsErrorModel, IConversationParent>(
        response
      ) as IContactsErrorModel;
      yield put(contactsFetchFailure(error.errorReason));
      return;
    }
    const data = unwrapEither<IContactsErrorModel, IConversationParent>(
      response
    ) as IConversationParent;
    yield put(contactsFetchSuccess(data));
  } catch (e) {
    yield put(
      contactsFetchFailure(
        e.Message || "error while triggering saga for the fetch contacts action"
      )
    );
  }
}

function* updateContactReadCount(payload) {
  yield call(updateMessageCountOnRead, payload.accountId, payload.contact);
}

function* modifyContact(payload) {
  const { key, modifiedContent, account } = payload; //key for the modified contact from the firebase subscription
  try {
    const messageWhenContactDetailsUpdated: Either<
      IErrorModel,
      IMessageInformation
    > = yield call(
      getMessageByMessageId,
      account,
      key,
      modifiedContent.lastmessage
    );
    if (isLeft(messageWhenContactDetailsUpdated)) {
      //no updates - update global error
      console.log("error in the modify contact");
    } else {
      //dispatch the update event and handle everything in the reducer
      //put the contact on the top of current list
      const messageInformation = messageWhenContactDetailsUpdated.right;
      const conversationListItem = {
        type: messageInformation.type,
        status: modifiedContent.ticketstatus,
        unreadcount: modifiedContent.unreadcount,
        key: key,
        timestamp: messageInformation.timestamp,
        [messageInformation.type]: messageInformation[messageInformation.type],
        originalcontact: modifiedContent.originalcontact || key,
      } as IConversationListItem;
      yield put(contactModifiedSuccess(conversationListItem));
    }
  } catch (e) {
    //no update - update global error
  }
}

function* removeContact(payload) {
  const { key } = payload;
  yield put(contactRemovedSuccess(key));
}

function* addContact(payload) {
  const { key, modifiedContent, account } = payload; //key for the modified contact from the firebase subscription
  try {
    const messageWhenContactDetailsUpdated: Either<
      IErrorModel,
      IMessageInformation
    > = yield call(
      getMessageByMessageId,
      account,
      key,
      modifiedContent.lastmessage
    );
    if (isLeft(messageWhenContactDetailsUpdated)) {
      //no updates - update global error
      console.log("error in the adding contact subscription");
    } else {
      //dispatch the update event and handle everything in the reducer
      //put the contact on the top of current list
      const messageInformation = messageWhenContactDetailsUpdated.right;
      const conversationListItem = {
        type: messageInformation.type,
        status: modifiedContent.ticketstatus,
        unreadcount: modifiedContent.unreadcount,
        key: key,
        timestamp: messageInformation.timestamp,
        [messageInformation.type]: messageInformation[messageInformation.type],
        originalcontact: modifiedContent.originalcontact || key,
      } as IConversationListItem;
      yield put(contactAddedSuccess(conversationListItem));
    }
  } catch (e) {
    //no update - update global error
  }
}

function* updateContactTicketStatus(payload) {
  try {
    const { updateTicketDetails } = payload;
    yield call(
      updateTicketStatus,
      updateTicketDetails.accountId,
      updateTicketDetails.contact,
      updateTicketDetails.ticketStatus
    );
    yield put(
      contactStatusUpdateSuccess({
        contact: updateTicketDetails.contact,
        status: updateTicketDetails.ticketStatus,
      })
    );
  } catch (e) {}
}

function* contactsSaga() {
  yield all([
    takeLatest(FETCH_CONTACTS_SAGA, fetchContactsSaga),
    takeLatest(UPDATE_CONTACT_READ_COUNT, updateContactReadCount),
    takeLatest(MODIFY_CONTACT_SAGA, modifyContact),
    takeLatest(ADD_CONTACT_SAGA, addContact),
    takeLatest(REMOVE_CONTACT_SAGA, removeContact),
    takeLatest(UPDATE_CONTACT_TICKET_STATUS, updateContactTicketStatus),
  ]);
}

export default contactsSaga;

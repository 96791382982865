import React from "react";
import useLoginInputs from "./useLoginInputs";
import {
  Container,
  CssBaseline,
  Box,
  Avatar,
  TextField,
  Button,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";

const theme = createTheme();

function Login() {
  const {
    onInputPhoneNumberChangeHandler,
    phoneNumber,
    onInputPasswordChangeHandler,
    password,
    submitLoginValuesHandler,
    isShowPassword,
    error,
  } = useLoginInputs();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" style={{ maxWidth: "304px" }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box>
            <TextField
              margin="normal"
              required
              fullWidth
              id="phone"
              label="Phone Number"
              name="phone"
              autoFocus
              value={phoneNumber}
              onChange={onInputPhoneNumberChangeHandler}
            />
            {!isShowPassword && <div id="recaptcha-container"></div>}
            {isShowPassword && (
              <TextField
                margin="normal"
                required
                fullWidth
                name="code"
                label="code"
                id="password"
                value={password}
                autoFocus
                onChange={onInputPasswordChangeHandler}
              />
            )}
            {error && (
              <Typography
                component="span"
                sx={{ fontSize: "12px", color: "red" }}
              >
                {error}
              </Typography>
            )}
            <Button
              variant="contained"
              fullWidth
              sx={{ mt: 3, mb: 2, mx: "auto" }}
              onClick={submitLoginValuesHandler}
              disabled={!isShowPassword && password?.length < 1}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Login;

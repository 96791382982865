import { IMessageInformation, IMessages } from "../../../../models/message";
import { postJsonRequestHandler } from "../../../../application/requestHandler";
import {
  isRight,
  unwrapEither,
} from "../../../../framework/monads/either.monad";
import { getMessageByMessageId } from "../../../../application/collectionAccessor";
import { IErrorModel } from "../../../../models/errorModel";
import { TextMessageRequest } from "../../../../models/MessageTypes/textMessageRequest";

export interface IUseChatMessage {
  id: string;
}
export interface ISubmitCallback {
  (text: string): void;
}
export interface ISubmitMessageProps {
  message: string;
  submitFn: ISubmitCallback;
}

function createMessageRequest(text: string, id: string): TextMessageRequest {
  return {
    messaging_product: "whatsapp",
    recipient_type: "individual",
    to: id,
    type: "text",
    text: {
      preview_url: false,
      body: text,
    },
    timestamp: (Date.now() / 1000) | 0,
  };
}

export function useChatInput({ id }: IUseChatMessage) {
  async function submitNewMessage({ message, submitFn }: ISubmitMessageProps) {
    const results = await postJsonRequestHandler<string, TextMessageRequest>(
      "/whatsapp/sendmessage",
      createMessageRequest(message, id)
    );
    if (isRight(results)) {
      const messageId = unwrapEither(results);
      const newMessageFromStore = await getMessageByMessageId(
        "",
        id,
        messageId
      );
      if (isRight(newMessageFromStore)) {
        const message = unwrapEither<IErrorModel, IMessageInformation>(
          newMessageFromStore
        ) as IMessageInformation;
        console.log(
          "message information while trying to add new message",
          message
        );
      }
    }
    submitFn("");
  }

  return {
    submitNewMessage,
  };
}

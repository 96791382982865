import Icons from "assets/icons";

const allIcons = Icons;
// @ts-ignore
const Icon = ({ id, ...props }) => {
	// @ts-ignore
	const selectedIcon = allIcons[id];
	return selectedIcon ? selectedIcon(props) : null;
};

export default Icon;

import React, { FC } from "react";
import Icon from "components/Icon";
import { Link } from "react-router-dom";
import formatTime from "utils/formatTime";
import ContactsDisplayComponent from "./Display";
import { useSelector } from "react-redux";
import { getContacts } from "../../store/contacts/contacts.selector";
import { IConversationListItem } from "../../models/contactData";

interface IContactProps{
  contact: IConversationListItem,
  classNameString: string | undefined
}

const Contact : FC<IContactProps> = ({ contact, classNameString}) => {
  const { originalcontact, unreadcount, status, timestamp, key, type } =
    contact;
  let todayTimestamp = new Date().getTime();

  return (
    <Link
      className={`sidebar-contact${classNameString ? ` ${classNameString}` : ""}`}
      to={`/chat/${key}`}
    >
      <div
        className={`sidebar-contact__dot ${
          (todayTimestamp - (parseInt(timestamp) * 1000)) <= 86400000
            ? "green-indicator"
            : "red-indicator"
        }`}
      ></div>
      <div className="sidebar-contact__avatar-wrapper">
        <img
          src="/images.jpeg"
          alt={originalcontact || "profilePicture"}
          className="avatar"
        />
      </div>
      <div className="sidebar-contact__content">
        <div className="sidebar-contact__top-content">
          <h2 className="sidebar-contact__name"> {originalcontact} </h2>
          <span className="sidebar-contact__time">
            {formatTime(parseInt(timestamp))}
          </span>
        </div>
        <div className="sidebar-contact__bottom-content">
          <p className="sidebar-contact__message-wrapper">
            {status && (
              <Icon
                id={status === "sent" ? "singleTick" : "doubleTick"}
                aria-label={status}
                className={`sidebar-contact__message-icon ${
                  status === "read" ? "sidebar-contact__message-icon--blue" : ""
                }`}
              />
            )}
            <>
              <span
                className={`sidebar-contact__message ${
                  unreadcount && unreadcount > 0 ? "sidebar-contact__message--unread" : ""
                }`}
              >
                <ContactsDisplayComponent
                  type={type}
                  messageData={contact[contact.type]}
                />
              </span>
            </>
          </p>
          <div className="sidebar-contact__icons">
            {unreadcount != 0 && (
              <span className="sidebar-contact__unread">{unreadcount}</span>
            )}
            <button aria-label="sidebar-contact__btn">
              <Icon
                id="downArrow"
                className="sidebar-contact__icon sidebar-contact__icon--dropdown"
              />
            </button>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Contact;

import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC8jzTTNrCCn9yGLUwoAKxLcFFnXcRFBmM",
  authDomain: "whatsappcloud-74658.firebaseapp.com",
  projectId: "whatsappcloud-74658",
  storageBucket: "whatsappcloud-74658.appspot.com",
  messagingSenderId: "817306550009",
  appId: "1:817306550009:web:71c0731d3beeeedc666c28",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const authApp = getAuth(app);

import { IConversationListItem } from "../../models/contactData";
import Contact from "./Contact";
import React from "react";

export interface IContactList {
  id: string;
  contacts: IConversationListItem[];
}

function ContactList({ id, contacts }: IContactList) {
  return (
    <div className="sidebar__contacts">
      {contacts &&
        contacts.map((contact: IConversationListItem) => (
          <Contact
            key={contact.key}
            contact={contact}
            classNameString={
              id != undefined &&
              contact.key == id &&
              "sidebar-content__selected"
            }
          />
        ))}
    </div>
  );
}

export default ContactList;

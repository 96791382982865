import axios from "axios";
import { Either, makeLeft, makeRight } from "../framework/monads/either.monad";
import { IErrorModel } from "../models/errorModel";
import { getToken } from "../utils/utility";
import header from "../pages/Chat/components/Header";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SEND_PATH,
  headers: {
    "Content-Type": "application/json",
  },
});

export async function getJsonRequestHandler<T>(
  url: string
): Promise<Either<IErrorModel, T>> {
  try {
    const { data, status, statusText } = await axiosInstance.get(url, {
      headers: {
        "X-FIREBASE-IDTOKEN": getToken(),
      },
    });
    if (status >= 200 && status < 300) {
      return makeRight<T>(data);
    } else {
      const errorMessage = `error while making get request for ${url}`;
      return makeLeft<IErrorModel>({
        error: errorMessage,
        errorReason: statusText || errorMessage,
      });
    }
  } catch (error) {
    return makeLeft<IErrorModel>({
      error: error,
      errorReason: error.message || `exception while calling ${url}`,
    });
  }
}

export async function postJsonRequestHandler<T, U>(
  url: string,
  data: U
): Promise<Either<IErrorModel, T>> {
  try {
    console.log("var here are", process.env.REACT_APP_SEND_PATH);
    const response = await axiosInstance.post(
      url,
      { ...data },
      {
        headers: {
          "X-FIREBASE-IDTOKEN": getToken(),
        },
      }
    );
    const { status, statusText } = response;
    if (status >= 200 && status < 300) {
      return makeRight<T>(response.data);
    } else {
      const errorMessage = `error while making get request for ${url}`;
      return makeLeft<IErrorModel>({
        error: errorMessage,
        errorReason: statusText || errorMessage,
      });
    }
  } catch (error) {
    return makeLeft<IErrorModel>({
      error: error,
      errorReason: error.message || `exception while calling ${url}`,
    });
  }
}

function formatTime(timeStamp: number): string {
  const time = new Date(timeStamp * 1000);
  const hours =
    time.getHours()?.toString().length === 1
      ? `0${time.getHours()}`
      : time.getHours();
  const minutes =
    time.getMinutes()?.toString().length === 1
      ? `0${time.getMinutes()}`
      : time.getMinutes();
  return `${hours}:${minutes}`;
}

export default formatTime;

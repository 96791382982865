import { IConversationListItem } from "../models/contactData";
import { IMessageInformation } from "../models/message";

export function decodeToken<T>(token: string): T {
  return JSON.parse(atob(token)) as T;
}

export function sortContactsWithTimeStamp(values: IConversationListItem[]) {
  values.sort(
    (first, second) => parseInt(second.timestamp) - parseInt(first.timestamp)
  );
}

export function getToken(): string {
  const token = localStorage.getItem("accessToken");
  const serializedData = JSON.parse(token);
  return serializedData.idToken;
}

export function addSubscriptionMessage(
  existingState: {},
  addedMessage: IMessageInformation
) {
  const time = new Date();
  const todayMessageKey = `${time.getDate()}/${
    time.getMonth() + 1
  }/${time.getFullYear()}`;
  let existingMessages = {
    ...existingState
  } as IMessageInformation[];
  if (existingState[todayMessageKey]) {
    existingMessages[todayMessageKey] = [
      ...existingMessages[todayMessageKey],
      addedMessage
    ];
  } else {
    existingMessages[todayMessageKey] = [addedMessage];
  }
  existingMessages["lastMessageTimeStamp"] = addedMessage.timestamp;
  return existingMessages;
}

export function mergeMessagesFromContact(alreadyExisting, lazyLoaded) {
  if (!lazyLoaded || !lazyLoaded.size) {
    return alreadyExisting;
  }
  const mergedMap = new Map();

  for (let [key, value] of lazyLoaded) {
    if (key !== "lastMessageTimeStamp") {
      if (alreadyExisting.has(key) && Array.isArray(alreadyExisting.get(key))) {
        const existingValue = alreadyExisting.get(key);
        mergedMap.set(key, [...value, ...existingValue]);
      } else {
        mergedMap.set(key, value);
      }
    } else {
      mergedMap.set(
        "lastMessageTimeStamp",
        alreadyExisting.get("lastMessageTimeStamp")
      );
    }
  }
  return mergedMap;
}

export function updateStateBasedOnContactClassification(existingState, newContact, contactClassification) {
  const currentTimeStamp = (Date.now() / 1000) | 0;
  if (currentTimeStamp - newContact.timestamp >= 86400) {
    existingState["Delayed"] = [...existingState["Delayed"], { ...newContact }];
    sortContactsWithTimeStamp(existingState["Delayed"]);
  } else {
    existingState["Last 24 Hours"] = [...existingState["Last 24 Hours"], { ...newContact }];
    sortContactsWithTimeStamp(existingState["Last 24 Hours"]);
  }
}

import Icon from "components/Icon";
import React, { FC, Fragment, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import formatTime from "utils/formatTime";
import { IMessageInformation } from "../../../models/message";

interface IConversation {
  messages: {};
}

const Conversation: FC<IConversation> = ({
  messages: allMessages,
}: IConversation) => {
  const lastMsgRef = useRef();
  const dispatch = useDispatch();

  function scrollToLastMsg() {
    if (lastMsgRef.current) {
      //@ts-ignore
      lastMsgRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }

  useEffect(function subscribeToMessages() {}, []);

  useEffect(
    function EmptyDepSideEffects() {
      if (Object.keys(allMessages).length <= 50) scrollToLastMsg();
    },
    [lastMsgRef.current, allMessages]
  );

  const dates = Array.from(Object.keys(allMessages));
  const messagesOutput = dates.map((date) => {
    const messages = allMessages[date];
    const lastTimeStamp = (messages[messages.length - 1] as IMessageInformation)
      .timestamp;
    const timeDiff =
      new Date().getTime() - new Date(parseInt(lastTimeStamp) * 1000).getTime();
    const daysDiff = timeDiff / (1000 * 60 * 60 * 24);
    if (Array.isArray(messages)) {
      return (
        <div key={date} className="conversation-container">
          <div className="chat__date-wrapper">
            <span className="chat__date">
              {" "}
              {daysDiff < 1 ? "Today" : daysDiff < 2 ? "Yesterday" : date}
            </span>
          </div>
          <button
            aria-label="Message options"
            className="chat_scroll-bottom"
            onClick={scrollToLastMsg}
          >
            <Icon id="downArrow" className="chat__msg-options-icon" />
          </button>
          <div className="chat__msg-group">
            {messages.map((message: any) => {
              return (
                <Fragment key={message.id}>
                  {message.image ? (
                    <div
                      className={`chat__msg chat__img-wrapper ${
                        message.from ? "chat__msg--rxd" : "chat__msg--sent"
                      }`}
                    >
                      <img src={``} alt="" className="chat__img" />
                      <span className="chat__msg-footer">
                        <span>{formatTime(message.timestamp)}</span>
                        {!message.from && (
                          <Icon
                            id={
                              message?.status === "sent"
                                ? "singleTick"
                                : "doubleTick"
                            }
                            aria-label={message?.status}
                            className={`chat__msg-status-icon ${
                              message?.status === "read"
                                ? "chat__msg-status-icon--blue"
                                : ""
                            }`}
                          />
                        )}
                      </span>
                    </div>
                  ) : message.from ? (
                    <p className="chat__msg chat__msg--rxd">
                      {message.text?.body ? (
                        <span>{message.text.body}</span>
                      ) : (
                        <span>{`Type: ${message.type}`}</span>
                      )}
                      <span className="chat__msg-filler"> </span>
                      <span className="chat__msg-footer">
                        {formatTime(message.timestamp)}
                      </span>
                    </p>
                  ) : (
                    <p className="chat__msg chat__msg--sent">
                      {message.text?.body ? (
                        <span>{message.text.body}</span>
                      ) : (
                        <span>{`Type: ${message.type}`}</span>
                      )}
                      <span className="chat__msg-filler"> </span>
                      <span className="chat__msg-footer">
                        <span> {formatTime(message.timestamp)} </span>
                        <Icon
                          id={
                            message?.status === "sent"
                              ? "singleTick"
                              : "doubleTick"
                          }
                          aria-label={message?.status}
                          className={`chat__msg-status-icon ${
                            message?.status === "read"
                              ? "chat__msg-status-icon--blue"
                              : ""
                          }`}
                        />
                      </span>
                    </p>
                  )}
                  <div className="bottomRef" ref={lastMsgRef}></div>
                </Fragment>
              );
            })}
          </div>
        </div>
      );
    }
  });
  return <>{messagesOutput}</>;
};

export default Conversation;

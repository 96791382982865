import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useDispatch } from "react-redux";
import { app, authApp } from "../../firebase/firebase.config";
import { FETCH_CONTACTS_SAGA } from "../../store/contacts/contacts.actiontypes";
import { GoogleUser } from "../../models/GoogleUser";

function useLoginInputs() {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [appVerifier, setAppVerifier] = useState<any>(null);
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [confirmationResult, setConfirmationResult] = useState<any>(null);
  const [isCaptchaSuccess, setCaptchaSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const dispatch = useDispatch();

  const navigation = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      navigation("/", {});
    } else {
      if (!isShowPassword) {
        const verifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "normal",
            callback: function (response: any) {
              //handle captcha success response
              setCaptchaSuccess(true);
            },
            "expired-callback": function () {
              //handle captcha failure response
            },
          },
          authApp
        );
        setAppVerifier(verifier);
      }
    }
  }, [isShowPassword]);

  useEffect(() => {
    if (!isShowPassword) {
      signInWithPhoneNumber(authApp, `+91${phoneNumber}`, appVerifier)
        .then((confirmationResult: any) => {
          setConfirmationResult(confirmationResult);
          setIsShowPassword(true);
        })
        .catch((err) => {
          if (phoneNumber.length > 12) {
            appVerifier.render().then((widgetId: any) => {
              grecaptcha.reset(widgetId);
            });
          }
        });
    }
  }, [isCaptchaSuccess, appVerifier, isShowPassword]);

  function onInputPhoneNumberChangeHandler(e: ChangeEvent<HTMLInputElement>) {
    setPhoneNumber(e.target.value);
  }

  function onInputPasswordChangeHandler(e: ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);
  }

  async function errorValidator(): Promise<boolean> {
    //todo: set development flag for dummy number
    const results = await authApp.currentUser.getIdTokenResult();
    console.log(results);
    console.log("claims in the error validator functions are ", results.claims);
    if (!results.claims["enabled"]) {
      setError("Your account is disabled, please contact the administrator");
      setIsShowPassword(false);
      return true;
    }
    if (
      results.claims["parents"] &&
      results.claims["parents"][0] === "x".repeat(10)
    ) {
      setError("You do not belongs to any account with us");
      setIsShowPassword(false);
      return true;
    }
    localStorage.setItem("parent", results.claims["parents"][0]);
    return false;
  }

  function submitLoginValuesHandler(e: any) {
    e.preventDefault();
    confirmationResult
      .confirm(password)
      .then(async (user: GoogleUser) => {
        const isError = await errorValidator();
        if (!isError) {
          const parent = localStorage.getItem("parent");
          dispatch({
            type: FETCH_CONTACTS_SAGA,
            phoneNumber: parent,
          });
          localStorage.setItem(
            "accessToken",
            JSON.stringify({
              ...user._tokenResponse,
              createdTime: user.user.createdAt,
              expirationTime: user.user.stsTokenManager.expirationTime,
            })
          );
          navigation("/");
        }
      })
      .catch((error) => {
        console.error("error while logging in is ", error);
        setIsShowPassword(false);
      });
  }

  return {
    onInputPasswordChangeHandler,
    password,
    onInputPhoneNumberChangeHandler,
    phoneNumber,
    submitLoginValuesHandler,
    isShowPassword,
    error,
  };
}

export default useLoginInputs;

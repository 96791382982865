import React, { useEffect, useState } from "react";
import { Box, ListItem, ListItemText } from "@mui/material";
import TimerIcon from "@mui/icons-material/Timer";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import TimerOffOutlinedIcon from "@mui/icons-material/TimerOffOutlined";

const IconMapping = {
  "Last 24 Hours": TimerIcon,
  Resolved: CheckBoxOutlinedIcon,
  Delayed: TimerOffOutlinedIcon
};

interface IContactTypes {
  updatedSelectedType: (selectedType: string) => void;
}

function ContactTypes({updatedSelectedType}: IContactTypes) {
  const [types, setTypes] = useState(["Last 24 Hours", "Resolved", "Delayed"]);
  const [selectedItem, setSelectedItem] = useState("");

  useEffect(() => {
    setSelectedItem(types[0]);
    updatedSelectedType(types[0]);
  }, []);

  function listItemClickHandler(e: any) {
    const clickedItem = e.currentTarget.getAttribute("data-current-item");
    console.log("clicked item is ", clickedItem);
    setSelectedItem(clickedItem);
    updatedSelectedType(clickedItem);
  }

  return (
    <Box>
      {types.map((type) => (
        <ListItem
          key={type}
          data-current-item={type}
          className={`contactTypes_list${
            type == selectedItem ? " contactTypes_list_selected" : ""
          }`}
          onClick={listItemClickHandler}
        >
          {React.createElement(IconMapping[type], {
            style: { marginRight: "20px" }
          })}
          <ListItemText primary={type} />
        </ListItem>
      ))}
    </Box>
  );
}

export default ContactTypes;

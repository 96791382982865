import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice, current } from "@reduxjs/toolkit";
import { IContactsState } from "./contacts.types";
import { IConversationParent } from "../../models/contactData";
import { sortContactsWithTimeStamp, updateStateBasedOnContactClassification } from "../../utils/utility";

const initialState = {
  subscribers: {
    pending: true,
    contacts: { "Resolved": [], "Delayed": [], "Last 24 Hours": [] },
    error: ""
  } as IContactsState
};

export const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    contactsFetchSuccess: (
      state,
      action: PayloadAction<IConversationParent>
    ) => {
      state.subscribers.pending = false;
      state.subscribers.contacts = action.payload;
    },
    contactsFetchFailure: (state, action) => {
      state.subscribers.error = action.payload;
    },
    contactModifiedSuccess: function modifyContactSuccessReducer(
      state,
      action
    ) {
      console.log("<<<contact modified reducer called...");
      const existingState = current(state.subscribers.contacts);
      const clonedState = { ...existingState };
      for (let key in clonedState) {
        const contactIndex = clonedState[key].findIndex(contact => contact.key === action.payload.key);
        if (contactIndex !== -1) {
          const currentItem = clonedState[key][contactIndex];
          if (action.payload.status === "resolved") {
            clonedState[key] = clonedState[key].filter(contact => contact !== action.payload.key);
            var obj = { ...action.payload };
            console.log("obj is ", obj);
            clonedState["Resolved"] = [...clonedState["Resolved"], { ...action.payload }];
            // if(currentItem.status !== 'resolved') {
            //   clonedState[key] = clonedState[key].filter(contact => contact !== action.payload.key);
            // }
            sortContactsWithTimeStamp(clonedState["Resolved"]);
            updateStateBasedOnContactClassification(clonedState, action.payload, key);
          } else {
            clonedState[key] = clonedState[key].filter(contact => contact.key !== action.payload.key);
            updateStateBasedOnContactClassification(clonedState, action.payload, key);
          }
          //check the correct bucket for the contact
          //either the last 24 hours, resolved or delayed

        }
      }
      state.subscribers.contacts = clonedState;
    },
    contactModifiedFailure: function modifyContactFailureReducer(
      state,
      action
    ) {
    },
    contactAddedSuccess: function addedContactSuccessReducer(state, action) {
      const existingState = current(state.subscribers.contacts);
      const clonedState = { ...existingState };
      clonedState["Last 24 Hours"].unshift(action.payload);
      // clonedState.unshift(action.payload);
      state.subscribers.contacts = clonedState;
    },
    contactStatusUpdateSuccess: function ticketStatusUpdated(state, action) {
      const existingState = current(state.subscribers.contacts);
      const { status } = action.payload;
      const clonedState = { ...existingState };
      console.log("<<<contact status updated reducer called...");

      for (let key in clonedState) {
        const contactIndex = clonedState[key].findIndex(contact => contact.key === action.payload.contact);
        if (contactIndex !== -1) {
          const existingContact = clonedState[key][contactIndex];
          //logic to find the correct place to move contact to
          //find timestamp and then move accordingly
          if (status === "resolved") {
            clonedState["Resolved"] = [...clonedState["Resolved"], existingContact];
            clonedState[key] = clonedState[key].filter(contact => contact.key !== action.payload.contact);
            sortContactsWithTimeStamp(clonedState["Resolved"]);
          } else {
            const currentTimeStamp = (Date.now() / 1000) | 0;
            if (currentTimeStamp - parseInt(existingContact.timestamp) >= 86400) {
              clonedState[key] = clonedState[key].filter(contact => contact.key !== action.payload.contact);
              clonedState["Delayed"] = [...clonedState["Delayed"], existingContact];
              sortContactsWithTimeStamp(clonedState["Delayed"]);
            } else {
              clonedState[key] = clonedState[key].filter(contact => contact.key !== action.payload.contact);
              clonedState["Last 24 Hours"] = [...clonedState["Last 24 Hours"], existingContact];
              sortContactsWithTimeStamp(clonedState["Last 24 Hours"]);
            }
          }
          break;
        }
      }
      state.subscribers.contacts = clonedState;
    },
    contactAddedFailure: function addedContactFailureReducer(state, action) {
    },
    contactRemovedSuccess: function removedContactSuccessReducer(
      state,
      action
    ) {
      const existingState = { ...current(state.subscribers.contacts) };
      // const contactsAfterRemoval = existingState.filter(
      //   (contact) => contact.key !== action.payload
      // );
      // state.subscribers.contacts = contactsAfterRemoval;
    },
    contactRemovedFailure: function removedContactFailureReducer(
      state,
      action
    ) {
    }
  },
  extraReducers: (builder) => {
  }
});

export const {
  contactsFetchSuccess,
  contactsFetchFailure,
  contactModifiedSuccess,
  contactModifiedFailure,
  contactAddedSuccess,
  contactAddedFailure,
  contactRemovedSuccess,
  contactRemovedFailure,
  contactStatusUpdateSuccess
} = contactsSlice.actions;

import React from "react";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import VideoCamIcon from "@mui/icons-material/Videocam";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import { IMessage } from "../../models/contactData";

interface IContactsDisplayComponent {
  messageData: any;
  type: string;
}

function DisplayTemplate({ text }: any) {
  return <FormatAlignRightIcon />;
}

function DisplayTextMessage({ text }: any) {
  return <>{text}</>;
}

function DisplayImage({ text }: any) {
  return <PhotoCameraIcon />;
}

function DisplayVideo({ text }: any) {
  return <VideoCamIcon />;
}

function ContactsDisplayComponent(props: IContactsDisplayComponent) {
  const typeComponentMap = {
    text: DisplayTextMessage,
    video: DisplayVideo,
    image: DisplayImage,
    interactive: DisplayTemplate,
  };
  if (props.type) {
    if (typeComponentMap[props.type]) {
      return typeComponentMap[props.type]({
        text: props.messageData?.body,
      });
    } else {
      console.log("type for unsupported message is ", props.type);
      return DisplayTextMessage({ text: props.messageData?.body || "" });
    }
  }
}

export default ContactsDisplayComponent;

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IMessageInformation } from "../../models/message";
import { getMessages } from "../../store/messages/messages.selector";
import { ADD_MESSAGE_SAGA } from "store/messages/messages.actiontypes";
import { getContacts } from "../../store/contacts/contacts.selector";

enum MessageTypes {
  conversation = 1,
  Error,
}

export interface IUseChatMessage {
  id: string;
}

export interface ISubmitCallback {
  (text: string): void;
}

export interface ISubmitMessageProps {
  message: string;
}

export function useChatMessages({ id }: IUseChatMessage) {
  //todo: find option for defining JS maps with dynamic keys - no need as redux accept primitive data types only
  const transformedMessages: {} =
    useSelector(getMessages);
  const contactDetails = useSelector(getContacts);

  const currentContactDetails = Object.keys(contactDetails).reduce((accumulatedValue, currentKey) => {
    const itemIndex = contactDetails[currentKey].findIndex((contact) => contact.key === id);
    if (itemIndex !== -1) {
      accumulatedValue.push(contactDetails[currentKey][itemIndex]);
    }
    return accumulatedValue;
  }, []);
  const currentContactStatus = Array.isArray(currentContactDetails) && currentContactDetails.length > 0 ? currentContactDetails[0].status : "";

  const [newMessage, setNewMessage] = useState<string>("");
  const dispatch = useDispatch();

  function checkMessageType(message: IMessageInformation): MessageTypes {
    if ((message.from || message.to) && message.timestamp)
      return MessageTypes.conversation;
    if (message.error_message || message.error_code) return MessageTypes.Error;
  }

  function updateMessageOnAddSubscription(addedMessage: IMessageInformation) {
    const messageType = checkMessageType(addedMessage);

    if (messageType == MessageTypes.conversation) {
      dispatch({ type: ADD_MESSAGE_SAGA, addedMessage });
    }
  }

  return {
    transformedMessages,
    newMessage,
    setNewMessage,
    currentContactStatus,
    updateMessageOnAddSubscription,
    currentContactDetails
  };
}

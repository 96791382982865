import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { IMessageInformation, IMessages } from "../../models/message";
import { addSubscriptionMessage, mergeMessagesFromContact } from "../../utils/utility";

const initialMessagesState = {
  conversations: {
    pending: true,
    messages: { },
    error: ""
  }
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState: initialMessagesState,
  reducers: {
    messagesFetchSuccess: function(state, action: PayloadAction<IMessages>) {
      state.conversations.pending = false;
      state.conversations.messages = action.payload.transformedMessages;
    },
    messagesFetchFailure: function(state, action) {
      state.conversations.pending = false;
      state.conversations.error = action.payload;
    },
    messageAddedSuccess: function(
      state,
      action: PayloadAction<IMessageInformation>
    ) {
      state.conversations.messages = addSubscriptionMessage(current(state.conversations.messages) , action.payload.addedMessage);
    },
    messagesFetchLazySuccess: function(state, action) {
      console.log('current staTE', current(state));
      state.conversations = {messages: mergeMessagesFromContact(current(state.conversations.messages), action.payload.transformedMessages), pending: false, error: ""};
    },
    messagesFetchLazyFailure: function(state, action) {

    }
  },
  extraReducers: function(builder) {
    return {};
  }
});

export const {
  messagesFetchSuccess,
  messagesFetchFailure,
  messageAddedSuccess,
  messagesFetchLazySuccess,
  messagesFetchLazyFailure
} =
  messagesSlice.actions;

import { Box, MenuItem, Select } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { UPDATE_CONTACT_TICKET_STATUS } from "../../store/contacts/contacts.actiontypes";
import { useParams } from "react-router-dom";

export interface IChatHeader {
  status: string;
}

const ChatHeader = ({ status }: IChatHeader) => {
  const [ticketStatus, setTicketStatus] = useState("");
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    setTicketStatus(status);
  }, [status]);

  function handleStatusChange(updatedStatus: string) {
    setTicketStatus(updatedStatus);
    const phoneNumber = localStorage.getItem("parent");
    const updateTicketDetails = {
      ticketStatus: updatedStatus,
      accountId: phoneNumber,
      contact: id,
    };
    dispatch({
      type: UPDATE_CONTACT_TICKET_STATUS,
      updateTicketDetails,
    });
  }
  return (
    <Box style={{ background: "white", borderRadius: "4px", height: "40px" }}>
      {status && (
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          style={{
            marginLeft: "15px",
            marginTop: "10px",
            height: "50%",
            width: "14%",
            zIndex: 9,
          }}
          value={ticketStatus}
          label="Status"
          onChange={(e) => {
            handleStatusChange(e.target.value);
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="open">Open</MenuItem>
          <MenuItem value="resolved">Resolved</MenuItem>
        </Select>
      )}
    </Box>
  );
};

export default ChatHeader;

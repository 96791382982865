import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getLazyLoadedMessagesByContact,
  getMessagesByContact
  // getAllMessagesByContact,
} from "../../application/collectionAccessor";
import {
  Either,
  isLeft,
  unwrapEither
} from "../../framework/monads/either.monad";
import { IContactsErrorModel } from "../../models/contactData";
import {
  messageAddedSuccess,
  messagesFetchFailure, messagesFetchLazyFailure, messagesFetchLazySuccess,
  messagesFetchSuccess
} from "./messages.slice";
import {
  FETCH_MESSAGES_SAGA,
  ADD_MESSAGE_SAGA, FETCH_PAGING_MESSAGES_SAGA
} from "./messages.actiontypes";
import { IMessageInformation, IMessages } from "../../models/message";

function* fetchMessagesSaga(payload) {
  try {
    const response: Either<IContactsErrorModel, IMessages> = yield call(
      getMessagesByContact,
      localStorage.getItem("parent"),
      payload.contactId
    );
    if (isLeft(response)) {
      const error = unwrapEither<IContactsErrorModel, IMessages>(
        response
      ) as IContactsErrorModel;
      yield put(messagesFetchFailure(error.errorReason));
      return;
    }
    const data = unwrapEither<IContactsErrorModel, IMessages>(response) as any;
    yield put(messagesFetchSuccess(data));
  } catch (err) {
    yield put(
      messagesFetchFailure(
        err?.messaage || err || "error while executing fetchMessagesSaga"
      )
    );
  }
}


function* addMessageSaga(payload) {
  yield put(messageAddedSuccess(payload));
}

function* fetchPagingMessageData(payload) {
  try {
    const response: Either<IContactsErrorModel, IMessages> = yield call(
      getLazyLoadedMessagesByContact,
      localStorage.getItem("parent"),
      payload.contactId,
      payload.lastMessageLoadedTimeStamp
    );
    if (isLeft(response)) {
      const error = unwrapEither<IContactsErrorModel, IMessages>(
        response
      ) as IContactsErrorModel;
      yield put(messagesFetchLazyFailure(error.errorReason));
      return;
    }
    const data = unwrapEither<IContactsErrorModel, IMessages>(response) as any;
    yield put(messagesFetchLazySuccess(data));
  } catch (err) {
    yield put(
      messagesFetchLazyFailure(
        err?.messaage || err || "error while executing fetchMessagesSaga"
      )
    );
  }
}

function* messagesFetchSaga() {
  yield all([
    takeLatest(FETCH_MESSAGES_SAGA, fetchMessagesSaga),
    takeLatest(ADD_MESSAGE_SAGA, addMessageSaga),
    takeLatest(FETCH_PAGING_MESSAGES_SAGA, fetchPagingMessageData)
  ]);
}

export default messagesFetchSaga;

import useChatInputWrapper from "./Application/useChatInputWrapper";
import EmojiTray from "./EmojiTray";
import ChatInput from "./ChatInput";

interface IChatInputWrapper {
  id: string;
}

function ChatInputWrapper({ id }: IChatInputWrapper) {
  const {
    newMessage,
    setNewMessage,
    showEmojis,
    setShowEmojis,
    showAttach,
    setShowAttach,
  } = useChatInputWrapper();
  return (
    <>
      <EmojiTray
        showEmojis={showEmojis}
        newMessage={newMessage}
        setNewMessage={setNewMessage}
      />
      <ChatInput
        showAttach={showAttach}
        setShowAttach={setShowAttach}
        showEmojis={showEmojis}
        setShowEmojis={setShowEmojis}
        newMessage={newMessage}
        setNewMessage={setNewMessage}
        id={id}
        click={() => {}}
      />
    </>
  );
}

export default ChatInputWrapper;

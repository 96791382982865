import { useState } from "react";

function useChatInputWrapper() {
  const [newMessage, setNewMessage] = useState<string>("");
  const [showAttach, setShowAttach] = useState<boolean>(false);
  const [showEmojis, setShowEmojis] = useState<boolean>(false);
  return {
    newMessage,
    setNewMessage,
    showAttach,
    setShowAttach,
    showEmojis,
    setShowEmojis,
  };
}

export default useChatInputWrapper;

import React, { FC, memo, useEffect, useState } from "react";
import Icon from "components/Icon";
import { ISubmitCallback, ISubmitMessageProps } from "../useChatMessages";
import { useChatInput } from "./Application/useChatInput";
import { v4 } from "uuid";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { useParams } from "react-router-dom";

const attachButtons = [
  { icon: "attachRooms", label: "Choose room", type: "rooms" },
  { icon: "attachContacts", label: "Choose contact", type: "contacts" },
  { icon: "attachDocument", label: "Choose document", type: "document" },
  { icon: "attachCamera", label: "Use camera", type: "camera" },
  { icon: "attachImage", label: "Choose image", type: "images" },
];

interface IChatInput {
  showAttach: boolean;
  setShowAttach: (isAttach: boolean) => void;
  showEmojis: boolean;
  setShowEmojis: (isEmoji: boolean) => void;
  newMessage: string;
  setNewMessage: (message: string) => void;
  id: string;
  click: any;
}

function ChatInput({
  showAttach,
  setShowAttach,
  showEmojis,
  setShowEmojis,
  newMessage,
  setNewMessage,
  id,
  click,
}: IChatInput) {
  const { submitNewMessage } = useChatInput({ id });
  const parent = localStorage.getItem("parent");
  const [img, setImg] = useState(null);
  const [loading, setLoading] = useState(false);

  const storage = getStorage();

  const params = useParams();
  console.log(parent);

  const detectEnterPress = async (e: any) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      await submitNewMessage({
        message: e.target.value,
        submitFn: setNewMessage,
      });
    }
  };
  const handleImageChange = (e) => {
    setImg(e.target.files[0]);
  };
  async function sendNewMsz(msz) {
    setLoading(true);
    await submitNewMessage({
      message: msz,
      submitFn: setNewMessage,
    });
    setLoading(false);
  }
  useEffect(() => {
    if (img === null) return;
    const imgRef = ref(storage, `${parent}/${params.id}/outbound/${img.name}`);
    uploadBytes(imgRef, img)
      .then(() => {
        console.log("Image Uploaded");
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, [img]);
  return (
    <div className="chat__input-wrapper">
      {showEmojis && (
        <button aria-label="Close emojis" onClick={() => setShowEmojis(false)}>
          <Icon id="cancel" className="chat__input-icon" />
        </button>
      )}
      <button aria-label="Emojis" onClick={() => setShowEmojis(true)}>
        <Icon
          id="smiley"
          className={`chat__input-icon ${
            showEmojis ? "chat__input-icon--highlight" : ""
          }`}
        />
      </button>
      {showEmojis && (
        <>
          <button aria-label="Choose GIF">
            <Icon id="gif" className="chat__input-icon" />
          </button>
          <button aria-label="Choose sticker">
            <Icon id="sticker" className="chat__input-icon" />
          </button>
        </>
      )}
      <div className="pos-rel">
        <button aria-label="Attach" onClick={() => setShowAttach(!showAttach)}>
          <Icon
            id="attach"
            className={`chat__input-icon ${
              showAttach ? "chat__input-icon--pressed" : ""
            }`}
          />
        </button>

        <div
          className={`chat__attach ${showAttach ? "chat__attach--active" : ""}`}
        >
          {attachButtons.map((btn) => (
            <button
              className="chat__attach-btn"
              aria-label={btn.label}
              key={btn.label}
            >
              <input
                accept="image/*"
                id="icon-button-file"
                type="file"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              <label htmlFor="icon-button-file">
                <Icon id={btn.icon} className="chat__attach-icon" />
              </label>
            </button>
          ))}
        </div>
      </div>
      <input
        className="chat__input"
        placeholder="Type a message"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        onKeyDown={detectEnterPress}
        autoFocus={true}
      />
      {newMessage ? (
        <button
          aria-label="Send message"
          onClick={() => {
            console.log(click());
            sendNewMsz(newMessage);
          }}
        >
          {loading ? (
            <Icon
              id="loading"
              className="chat__input-icon chat__loading-icon"
            />
          ) : (
            <Icon id="send" className="chat__input-icon" />
          )}
        </button>
      ) : (
        <button aria-label="Record voice note">
          <Icon id="microphone" className="chat__input-icon" />
        </button>
      )}
    </div>
  );
}

export default ChatInput;

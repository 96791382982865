import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { enableMapSet } from 'immer';
import logger from "redux-logger";

import { rootReducer } from "./root.reducer";
import { rootSaga } from "./root.saga";

enableMapSet()

const sagaMiddleWare = createSagaMiddleware();
const middleware = [...getDefaultMiddleware({ thunk: false }), sagaMiddleWare];
const store = configureStore({
  reducer: rootReducer,
  middleware,
});
sagaMiddleWare.run(rootSaga);
export default store;

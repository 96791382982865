import React, { useEffect, useState } from "react";
import "./styles/main.css";
import avatar from "assets/images/profile-picture-girl-1.jpeg";
import Icon from "components/Icon";
import OptionsBtn from "components/OptionsButton";
import { getContacts } from "../../store/contacts/contacts.selector";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_CONTACT_SAGA,
  FETCH_CONTACTS_SAGA,
  MODIFY_CONTACT_SAGA,
  REMOVE_CONTACT_SAGA
} from "../../store/contacts/contacts.actiontypes";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase/firebase.config";
import { collection, onSnapshot, query } from "firebase/firestore";
import ContactTypes from "./ContactTypes";
import ContactsWrapper from "./ContactsWrapper";

const Sidebar = () => {
  let contacts = useSelector(getContacts);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [selectedContactType, setSelectedContactType] = useState("");

  function getUpdatedSelectedType(value: string) {
    setSelectedContactType(value);
  }

  let phoneNumber;

  useEffect(() => {
    const accessToken: any = localStorage.getItem("accessToken");
    if (!accessToken) {
      navigation("/login", {});
    } else {
      if (!contacts || !contacts?.length) {
        phoneNumber = localStorage.getItem("parent");
        dispatch({
          type: FETCH_CONTACTS_SAGA,
          phoneNumber
        });
      }
    }
    let unsubscribe;
    if (!unsubscribe) {
      console.log("contacts subscription set on the collection");
      const parent = localStorage.getItem("parent");
      const subscriptionQuery = query(
        collection(db, "messagesdata", `account:${parent}`, "contacts")
      );
      unsubscribe = onSnapshot(
        subscriptionQuery,
        function contactSubscriptionCallback(snapshot) {
          snapshot
            .docChanges()
            .forEach(function messageSnapShotListener(change) {
              switch (change.type) {
                case "added":
                  if (
                    contacts.length > 0 &&
                    contacts.findIndex(
                      (contact) => contact.key == change.doc.id
                    ) === -1
                  ) {
                    dispatch({
                      type: ADD_CONTACT_SAGA,
                      key: change.doc.id,
                      modifiedContent: change.doc.data(),
                      account: parent
                    });
                  }
                  break;
                case "modified":
                  dispatch({
                    type: MODIFY_CONTACT_SAGA,
                    key: change.doc.id,
                    modifiedContent: change.doc.data(),
                    account: parent
                  });
                  break;
                case "removed":
                  dispatch({ type: REMOVE_CONTACT_SAGA, key: change.doc.id });
                  break;
              }
            });
        }
      );
      //setSubscriptionFlag(true);
    }
    return () => {
      unsubscribe && unsubscribe();
    };
  }, []);

  useEffect(() => {
  }, [contacts.length]);

  function searchContacts(keyword: string) {
  }

  return (
    <aside className="sidebar">
      <header className="header">
        <div className="sidebar__avatar-wrapper">
          <img src={avatar} alt="Karen Okonkwo" className="avatar" />
        </div>
        <div className="sidebar__actions">
          <button className="sidebar__action" aria-label="Status">
            <Icon
              id="status"
              className="sidebar__action-icon sidebar__action-icon--status"
            />
          </button>
          <button className="sidebar__action" aria-label="New chat">
            <Icon id="chat" className="sidebar__action-icon" />
          </button>
          <OptionsBtn
            className="sidebar__action"
            ariaLabel="Menu"
            iconId="menu"
            iconClassName="sidebar__action-icon"
            options={[
              "New group",
              "Create a room",
              "Profile",
              "Archived",
              "Starred",
              "Settings",
              "Log out"
            ]}
          />
        </div>
      </header>
      <ContactTypes updatedSelectedType={getUpdatedSelectedType} />
      <div className="search-wrapper">
        <div className="search-icons">
          <Icon id="search" className="search-icon" />
          <button className="search__back-btn">
            <Icon id="back" />
          </button>
        </div>
        <input
          className="search"
          placeholder="Search or start a new chat"
          onChange={(e) => {
            searchContacts(e.target.value);
          }}
        />
      </div>
      <ContactsWrapper id={id} contacts={contacts} selectedContactType={selectedContactType} />
      {/*<ContactList id={id} contacts={contacts} />*/}

    </aside>
  );
};

export default Sidebar;

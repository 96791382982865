import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ADD_MESSAGE_SAGA } from "../../store/messages/messages.actiontypes";
import { getMessages } from "store/messages/messages.selector";
import "./styles/main.css";
import EmojiTray from "./components/EmojiTray";
import ChatInput from "./components/ChatInput";
import Header from "./components/Header";
import ChatSidebar from "./components/ChatSidebar";
import Icon from "components/Icon";
import Search from "./components/Search";
import Profile from "./components/Profile";
import Conversation from "./components/Conversation";
import Sidebar from "../../components/Sidebar";
import { useChatMessages } from "./useChatMessages";
import {
  FETCH_MESSAGES_SAGA,
  FETCH_PAGING_MESSAGES_SAGA,
} from "../../store/messages/messages.actiontypes";
import { db } from "../../firebase/firebase.config";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import contacts from "../../data/contacts";
import { IMessageInformation } from "models/message";
import { UPDATE_CONTACT_READ_COUNT } from "../../store/contacts/contacts.actiontypes";
import { Box, InputLabel, MenuItem, Select } from "@mui/material";
import ChatHeader from "../../components/Chat/Header";
import ChatInputWrapper from "./components/ChatInputWrapper";

interface IChat {
  history?: any;
}

function Chat({ history }: IChat) {
  const { id } = useParams();
  const {
    transformedMessages,
    newMessage,
    setNewMessage,
    currentContactStatus,
    updateMessageOnAddSubscription,
    currentContactDetails,
  } = useChatMessages({ id });
  console.log(
    "current contact details are ",
    Array.isArray(currentContactDetails) &&
      currentContactDetails.length > 0 &&
      currentContactDetails[0]
  );
  const lastMessageTimeStamp =
    transformedMessages &&
    transformedMessages["lastMessageTimeStamp"] &&
    transformedMessages["lastMessageTimeStamp"];

  //let user = users.filter((user: any) => user.id === Number(id))[0];
  const dispatch = useDispatch();
  //const [status, setStatus] = useState(Array.isArray(currentContactDetails) && currentContactDetails.length > 0 && currentContactDetails[0].status);

  const [showProfileSidebar, setShowProfileSidebar] = useState<boolean>(false);
  const [showSearchSidebar, setShowSearchSidebar] = useState<boolean>(false);
  const allStatus = [
    { key: "open", value: "open" },
    { key: "Resolved", value: "resolved" },
  ];

  useEffect(() => {
    dispatch({
      type: FETCH_MESSAGES_SAGA,
      contactId: id,
    });
  }, [id]);

  useEffect(() => {
    let unsubscribe;
    if (lastMessageTimeStamp) {
      const parent = localStorage.getItem("parent");
      currentContactDetails[0] &&
        currentContactDetails[0].unreadcount > 0 &&
        dispatch({
          type: UPDATE_CONTACT_READ_COUNT,
          accountId: parent,
          contact: id,
        });
      if (!currentContactDetails) {
        dispatch({
          type: UPDATE_CONTACT_READ_COUNT,
          accountId: parent,
          contact: id,
        });
      }

      const subscriptionQuery = query(
        collection(
          db,
          "messagesdata",
          `account:${parent}`,
          "contacts",
          id,
          "messages"
        ),
        orderBy("timestamp"),
        startAfter(lastMessageTimeStamp)
      );
      //unsubscribe && unsubscribe();
      console.log("messages subscription set up now");
      unsubscribe = onSnapshot(
        subscriptionQuery,
        function messageSubscriptionCallback(snapshot) {
          snapshot
            .docChanges()
            .forEach(function messageSnapShotListener(change) {
              switch (change.type) {
                case "added":
                  console.log(
                    "New message added on firebase subscription",
                    change.doc.data()
                  );
                  updateMessageOnAddSubscription(
                    change.doc.data() as IMessageInformation
                  );
                  //scrollToLastMsg();
                  break;
                case "modified":
                  console.log(
                    "Message modified on firebase subscription",
                    change.doc.data()
                  );
                  break;
                case "removed":
                  console.log(
                    "Message removed on firebase subscription",
                    change.doc.data()
                  );
                  break;
              }
            });
        }
      );
      return () => {
        unsubscribe && unsubscribe();
      };
    }
  }, [lastMessageTimeStamp]);

  const openSidebar = (cb: (val: boolean) => void) => {
    // close any open sidebar first
    setShowProfileSidebar(false);
    setShowSearchSidebar(false);

    // call callback fn
    cb(true);
  };

  function handleConversationScroll(e) {
    if (e.currentTarget.scrollTop === 0) {
      const firstMessageKey =
        transformedMessages &&
        Object.keys(transformedMessages).length &&
        Array.from(Object.keys(transformedMessages))[0];
      const lastMessageLoadedTimeStamp =
        firstMessageKey &&
        typeof transformedMessages[firstMessageKey] !== "string" &&
        (transformedMessages[firstMessageKey][0] as IMessageInformation)
          .timestamp;
      if (lastMessageLoadedTimeStamp) {
        dispatch({
          type: FETCH_PAGING_MESSAGES_SAGA,
          contactId: id,
          lastMessageLoadedTimeStamp,
        });
      }
    }
  }

  return (
    <div className="app">
      <div className="app-content">
        <Sidebar />
        <div className="chat">
          <div className="chat__body">
            <div className="chat__bg"></div>
            <div className="chat__content" onScroll={handleConversationScroll}>
              {transformedMessages && (
                <div>
                  <ChatHeader status={currentContactStatus} />
                  <Conversation messages={transformedMessages} />
                </div>
              )}
            </div>
            <footer className="chat__footer">
              {/*<></>*/}
              {/*<EmojiTray*/}
              {/*  showEmojis={showEmojis}*/}
              {/*  newMessage={newMessage}*/}
              {/*  setNewMessage={setNewMessage}*/}
              {/*/>*/}
              {/*<ChatInput*/}
              {/*  click={() => {}}*/}
              {/*  showEmojis={showEmojis}*/}
              {/*  setShowEmojis={setShowEmojis}*/}
              {/*  showAttach={showAttach}*/}
              {/*  setShowAttach={setShowAttach}*/}
              {/*  newMessage={newMessage}*/}
              {/*  setNewMessage={setNewMessage}*/}
              {/*  id={id}*/}
              {/*/>*/}
              <ChatInputWrapper id={id} />
            </footer>
          </div>
          <ChatSidebar
            heading="Search Messages"
            active={showSearchSidebar}
            closeSidebar={() => setShowSearchSidebar(false)}
          >
            <Search />
          </ChatSidebar>

          <ChatSidebar
            heading="Contact Info"
            active={showProfileSidebar}
            closeSidebar={() => setShowProfileSidebar(false)}
          >
            {/*todo: Parent User Profile Component*/}
            {/*{user && user.name(<Profile user={user} />)}*/}
          </ChatSidebar>
        </div>
      </div>
    </div>
  );
}

export default Chat;

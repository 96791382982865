import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebase.config";
import {
  IContactData,
  IContactsErrorModel,
  IConversationListItem,
  IConversationParent,
  IMessageData,
} from "../models/contactData";
import {
  Either,
  makeLeft,
  makeRight,
  unwrapEither,
} from "../framework/monads/either.monad";
import { sortContactsWithTimeStamp } from "../utils/utility";
import { IMessageInformation, IMessages } from "../models/message";
import { IErrorModel } from "../models/errorModel";

export async function getContactsByAccount(
  phoneNumber: string
): Promise<Either<IContactsErrorModel, IConversationParent>> {
  try {
    //console.log("getContactsByAccount phone number is ", phoneNumber);
    const collectionReference = collection(
      db,
      "messagesdata",
      `account:${phoneNumber}`,
      "contacts"
    );
    let allContacts = await getDocs(collectionReference);

    let contactsData: IContactData[] = allContacts.docs.map((contact) => {
      return {
        data: contact.data() as IMessageData,
        key: contact.id as string,
      };
    });

    let lastMessagePromise = contactsData.map(
      function createPromiseForLastMessage(contact) {
        return getMessageByMessageId(
          phoneNumber,
          contact.key,
          contact.data.lastmessage
        );
      }
    );

    let contactsList = await Promise.all(lastMessagePromise);

    let contacts = contactsList.map(function transformContacts(contact, index) {
      //not checking right, since if the response is resolved in Promise.all it is correct
      const messageInformation = unwrapEither(contact) as IMessageInformation;
      return {
        type: messageInformation.type,
        status: contactsData[index].data.ticketstatus,
        unreadcount: contactsData[index].data.unreadcount,
        key: contactsData[index].key,
        timestamp: messageInformation.timestamp,
        [messageInformation.type]: messageInformation[messageInformation.type],
        originalcontact:
          contactsData[index].data.originalcontact ||
          `+${contactsData[index].key}`,
      } as IConversationListItem;
    });

    let contactsWithoutTimeStamp: IConversationListItem[] = [];
    let contactsWithTimeStamp = contacts.filter(
      (contact: IConversationListItem) => {
        if (contact.timestamp) return contact;
        contactsWithoutTimeStamp.push(contact);
      }
    );

    sortContactsWithTimeStamp(contactsWithTimeStamp);

    // let unreadContacts: IConversationListItem[] = [];
    // let readContacts = contactsWithTimeStamp.filter(
    //   (contact: IConversationListItem) => {
    //     if (!contact.unreadcount) return contact;
    //     unreadContacts.push(contact);
    //   }
    // );

    // return makeRight<IConversationListItem[]>([
    //   ...unreadContacts,
    //   ...readContacts,
    //   ...contactsWithoutTimeStamp,
    // ]);
    const currentTimeStamp = (Date.now() / 1000) | 0;
    const segregatedMessages: IConversationParent =
      contactsWithTimeStamp.reduce(
        (accumulator, current) => {
          if (current.status === "resolved") {
            accumulator["Resolved"].push(current);
          }

          if (currentTimeStamp - parseInt(current.timestamp) <= 86400) {
            accumulator["Last 24 Hours"].push(current);
          } else {
            accumulator["Delayed"].push(current);
          }
          return accumulator;
        },
        { "Last 24 Hours": [], Resolved: [], Delayed: [] }
      );

    console.log("segregated messages are ", segregatedMessages);
    // return makeRight<IConversationListItem[]>([
    //   ...contactsWithTimeStamp,
    //   ...contactsWithoutTimeStamp,
    // ]);
    return makeRight<IConversationParent>({
      ...segregatedMessages
    });
  } catch (e) {
    return makeLeft<IContactsErrorModel>({
      error: e,
      errorReason:
        e.Message || "Error while getting the contacts data from back",
    });
  }
}

export async function getContactByAccount(
  account: string,
  contactKey: string
): Promise<Either<IContactsErrorModel, IConversationListItem>> {
  try {
    const contactRef = doc(
      db,
      "messagesdata",
      `account:${account}`,
      "contacts",
      contactKey
    );
    const contactData = await getDoc(contactRef);
    return makeRight<IConversationListItem>(
      contactData.data() as IConversationListItem
    );
  } catch (e) {
    return makeLeft({
      error: e,
      errorReason: e.message || "Error while getting the contact doc",
    });
  }
}

export async function getMessageByMessageId(
  account: string,
  contactId: string,
  messageId: string
): Promise<Either<IErrorModel, IMessageInformation>> {
  try {
    //todo: development flag to get the dummy number
    const messageRef = doc(
      db,
      "messagesdata",
      `account:${account}`,
      "contacts",
      contactId,
      "messages",
      messageId
    );
    const message = await getDoc(messageRef);
    if (message && message.data()) {
      return makeRight<IMessageInformation>(
        message.data() as IMessageInformation
      );
    }
    return makeRight<IMessageInformation>({} as IMessageInformation);
  } catch (e) {
    return makeLeft({
      error: e,
      errorReason: e.message || "Error while getting the message by message id",
    });
  }
}

export async function getMessagesByContact(
  account: string,
  contactId: string
): Promise<Either<IContactsErrorModel, IMessages>> {
  try {
    const messageCollectionRef = collection(
      db,
      "messagesdata",
      `account:${account}`,
      "contacts",
      contactId,
      "messages"
    );

    const allMessages = await getDocs(
      query(messageCollectionRef, orderBy("timestamp", "desc"), limit(50))
    );
    const messages: any[] = allMessages.docs
      .reverse()
      .map(function messageFromMessageDoc(message) {
        return { ...message.data(), id: message.id as string };
      });

    const systemMessages = messages.filter(function getSystemMessages(message) {
      return message.type === "ticket-system";
    });

    const errorMessages = messages.filter(function getErrorMessages(message) {
      return message.error || message.errorReason;
    });

    const lastTimeStamp = messages[messages.length - 1].timestamp;
    const transformedMessage = transformMessagesByDate(messages);
    transformedMessage["lastMessageTimeStamp"] = lastTimeStamp;

    return makeRight<IMessages>({ transformedMessages: transformedMessage });
  } catch (err) {
    return makeLeft<IContactsErrorModel>({
      error: err,
      errorReason:
        err.Message || "Error while getting the contacts data from back",
    });
  }
}

export async function getLazyLoadedMessagesByContact(
  account: string,
  contactId: string,
  lastLoadedMessageTimeStamp: string
): Promise<Either<IContactsErrorModel, IMessages>> {
  try {
    const messageCollectionRef = collection(
      db,
      "messagesdata",
      `account:${account}`,
      "contacts",
      contactId,
      "messages"
    );

    const allMessages = await getDocs(
      query(
        messageCollectionRef,
        orderBy("timestamp", "desc"),
        startAfter(lastLoadedMessageTimeStamp),
        limit(50)
      )
    );
    const messages: any[] = allMessages.docs
      .reverse()
      .map(function messageFromMessageDoc(message) {
        return { ...message.data(), id: message.id as string };
      });

    const systemMessages = messages.filter(function getSystemMessages(message) {
      return message.type === "ticket-system";
    });

    const errorMessages = messages.filter(function getErrorMessages(message) {
      return message.error || message.errorReason;
    });

    const lastTimeStamp = messages[messages.length - 1].timestamp;
    const transformedMessage = transformMessagesByDate(messages);
    transformedMessage["lastMessageTimeStamp"] = lastTimeStamp;
    //console.log("lazy loaded messages are ", transformedMessage);
    return makeRight<IMessages>({
      transformedMessages: transformedMessage as IMessages,
    });
  } catch (err) {
    return makeLeft<IContactsErrorModel>({
      error: err,
      errorReason:
        err.Message || "Error while getting the contacts data from back",
    });
  }
}

export function transformMessagesByDate(messages: IMessageInformation[]): {} {
  const contactsMessages = messages.filter(
    (message) => message.from || message.to
  );

  const messagesByDate = contactsMessages.reduce((accumulator, current) => {
    const time = createDateKey(current.timestamp);
    if (accumulator[time]) {
      const existingValue = accumulator[time];
      existingValue.push(current);
      accumulator[time] = existingValue;
    } else {
      accumulator[time] = [{ ...current }];
    }
    return accumulator;
  }, {});
  console.log("messages by date are", messagesByDate);
  return messagesByDate;
}

function createDateKey(timestamp) {
  const time = new Date(timestamp * 1000);
  return `${time.getDate()}/${time.getMonth() + 1}/${time.getFullYear()}`;
}

export async function updateMessageCountOnRead(accountId, contact) {
  try {
    const contactRef = doc(
      db,
      "messagesdata",
      `account:${accountId}`,
      "contacts",
      contact
    );
    const contactDoc = await getDoc(contactRef);

    await updateDoc(contactRef, { unreadcount: 0 });
  } catch (err) {
    console.log("error while updating unread count for the contact");
  }
}

export async function updateTicketStatus(accountId, contact, ticketStatus) {
  try {
    const contactRef = doc(
      db,
      "messagesdata",
      `account:${accountId}`,
      "contacts",
      contact
    );
    await updateDoc(contactRef, { ticketstatus: ticketStatus });
  } catch (err) {
    console.log("error while updating unread count for the contact");
  }
}
